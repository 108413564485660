.swiper {
    width: 100%;
    height: 100%;
  }
  section.log-y {
    background-color: #f8f9fa;
    padding: 30px 0px 30px 0px !important;
    text-align: center;
  }
  img.sec-img {
    width: 100px;
  }
  @media (max-width: 425px) {
    section.log-y {
      padding: 25px 0px 25px 0px !important;
    }
  }