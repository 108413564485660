.about-area.ptb-80 {
  margin-top: 50px;
}
img.img-about {
  width: 100%;
}
span.home-abouts-30 {
  display: inline-block;
  color: #000;
  background-color: #f8edfd;
  margin-bottom: 18px;
  border-radius: 30px;
  padding: 5px 20px 4px;
  font-size: 13px;
}
.Home-about-content h2 {
  font-size: 26px !important;
  font-weight: 500;
  color: rgb(19 47 112) !important;
}

.Home-about-content .bar {
  height: 5px;
  width: 90px;
  background: #93cbcf7a;
  margin: 20px 0;
  position: relative;
  border-radius: 30px;
}

h2 strong {
  background: -webkit-linear-gradient(#00adb8, rgb(19 47 112 / 92%));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 28px;
  font-weight: 800;
}

/* @media (max-width: 991px) {
    .ml-about-content {
      margin-top: 20px;
    }
  } */

  @media (max-width: 767px) {
    img.img-about {
      width: 80%;
      margin: 55px;
    }
  }
  
  @media (max-width: 575px){

    img.img-about {
      width: 65%;
      margin: 0px 70px 12px 94px;
  }
  }

  @media (max-width: 430px){
    img.img-about {
      width: 65%;
      margin: 0px 62px 12px 68px;
  }
  }


@media (max-width: 424px) {
  img.img-about {
    margin: 0px 50px 0px 60px;
}
}

@media (max-width: 375px) {
  img.img-about {
    margin: 1px 50px 8px 56px;
}
}

.about-tile-h2-desktop {
  display: block;
}
.about-tile-h2-mobile {
  display: none;
}

@media (min-width: 110px) and (max-width: 575px) {

  .about-tile-h2-desktop {
    display: none;
  }

  .about-tile-h2-mobile {
    display: block;
  }
}







