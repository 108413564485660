.category-wrapper {
    background: #fff;
    border-radius: 5px;
    box-shadow: 5px 5px 24px 0 rgb(2 45 98 / 10%);
}
.ourproduct-25 img {
  width: 100%;
}
.our-products-updated-1 {
  display: none;
  background: #f0f0f0;
  padding: 50px 0px 40px 0px;
}
.our-product-update {
  display: block;
  background: #f0f0f0;
  padding: 50px 0px 40px 0px;
}
/* .grid-wrapper.grid-lg-6 {
    grid-template-columns: repeat(6,1fr);
} */
.category-icons {
  cursor:pointer;
  background: #fff;
    align-items: center;
    border-bottom: 1px solid #eee;
    border-right: 1px solid #eee;
    display: flex;
    justify-content: center;
    padding: 30px;
    position: relative;
    text-align: center;
}

.category-icons .category-item .item-icon {
    color: #c1b182;
    font-size: 28px;
    margin-bottom: 10px;
   
}
.category-icons .category-item .category-title {
  color: #000000ab;
  font-size: 14px;
  font-weight: 400;
}

section.our-20 {
    background-color: #fffafa6b;
    padding: 40px 0px 7px;
    position: relative;
    top: 0px;
}
.our-product {
    background-color: #f9f6f6;
    padding-bottom: 30px;
}
  .single-features a {
    color: #000;
    font-size: 14px;
    font-weight: 400;
}
.our-text-t210 h2 {
    color: #0e2b5c;
    font-size: 28px;
    max-width: 500px;
    margin: auto;
    font-weight: 400;
    line-height: 1.2em;
    padding-bottom: 15px;
}
.our-img img {
    width: 100%;
    height: 425px;
    object-fit: cover;
}
  .services-2 {
    margin-top: 10%;
  }
  .our-text-55 h2 {
    margin-bottom: 0;
    text-transform: capitalize;
    font-size: 27px;
    font-weight: 500;
    color: rgb(19 47 112);
}
.newslide-2 {
    margin: 20px 0px 0px 0px;
    text-align: center;
    box-shadow: 0 2px 0px 0 rgb(0 0 0 / 8%);
    background-color: #f9f0f0;
    padding: 31px 20px 30px 20px;
    border-radius: 30px 0px 30px 0px;
}
section.our-products-fasted {
    padding: 40px 0px;
}
.newslide-2 h1 {
    font-size: 21px;
    font-weight: 400;
    color: rgb(19 47 112);
}

  .single-features {
    position: relative;
    background: #fff;
    padding-left: 95px;
    padding-top: 30px;
    cursor: pointer;
    padding-right: 20px;
    padding-bottom: 30px;
    margin-bottom: 15px;
    border-radius: 5px;
    -webkit-transition: .5s;
    transition: .5s;
}
.single-features:hover {
  background: #62400236;
  position: relative;
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    -webkit-box-shadow: 0 2px 48px 0 rgb(0 0 0 / 8%);
    box-shadow: 0 2px 48px 0 rgb(0 0 0 / 8%);
}
.single-features .icon {
    position: absolute;
    text-align: center;
    width: 55px;
    height: 55px;
    font-size: 23px;
    line-height: 50px;
    background: #cdf1d8;
    border-radius: 50%;
    color: #44ce6f;
    left: 20px;
    top: 50px;
}
 .single-features h3 {
    font-size: 19px;
    margin-bottom: 7px;
    color:rgb(19 47 112);
    font-weight: 500;
}
.single-features .icon.bg-c679e3 {
    color: #c679e3;
    background: #edc3fc;
}
.single-features .icon.bg-eb6b3d {
    color: #eb6b3d;
    background: rgba(235,107,61,.3);
}
  .common-heading>span {
    color: #00adb8;
    font-size: 13px;
    font-weight: 500;
  }

.ml-about-content {
    text-align: center;
    padding: 45px 0px 45px 0px;
}
.grid-wrapper {
  position: relative;
  background-color: #fff !important;
    --gap-count: calc(var(--grid-column-count) - 1);
    --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
    --grid-item--max-width: calc((100% - var(--total-gap-width)) / var(--grid-column-count));
    --grid-layout-gap: 0px;
    --grid-column-count: 8;
    --grid-item--min-width: 237px;
    grid-template-columns: repeat(auto-fill, minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr));
    grid-gap: var(--grid-layout-gap);
    display: grid;
   
}
  .our62-img img {
    width: 100%;
}
.our-text-55 {
    text-align: center;
    padding: 60px 0px 0px 0px;
}
section.our-text-69 {
    position: relative;
    top: 146px;
    margin-bottom: 12%;
}
  .our-text-63 {
    position: relative;
    bottom: 111px;
    color: #fff;
    left: 19px;
    padding: 10px 0px 10px 13px;
    border: 2px solid #fff;
}
.our-text-63 p {
    color: #fff !important;
}
.newslide img {
    width: 100%;
   
}
@media(max-width:767px){
  .our-products-updated-1 {
    display: block;
    padding: 30px 0px 0px 0px;
}
.our-product-update {
  display: none;
}
.our-text-55 {
  padding: 21px 0px 0px 0px;
}
}
@media(max-width:575px){
  .our-text-55 h2 {
    font-size: 21px;
 
}
.single-features h3 {
  font-size: 18px;
}
.single-features {
  padding-left: 17px;
  padding-top: 67px;
  padding-right: 4px;
  margin-bottom: 0px;

}

.single-features .icon {
  left: 13px;
  top: 8px;
}
}
@media(max-width:498px){
  .grid-wrapper {
    --grid-item--min-width: 146px; 
}
.category-icons {
  padding: 10px;
}
.category-icons .category-item .item-icon {
  margin-bottom: 0px;
}
}
@media (max-width: 375px){
.our-text-55 h2 {
    font-size: 19px;
}
.newslide-2 h1 {
  font-size: 20px;
}

}
/* @media(max-width:991px){
    .common-heading h2 {
        margin-bottom: 17px;
    }
   
     section.our-text-69 {
        margin-bottom: 16%;
    }
}

@media(max-width:767px){
  .our-text-55 {
    padding: 15px 0px 0px 0px;
}
 section.our-products-fasted {
  padding: 21px 0px;
}
.newslide-2{
  margin:6px 0px 0px 0px;
}
 .our-text-63 {
    left: 0px;
   }
    .our-product-update {
      display: none;
  }
  .our-products-updated-1 {
    display: block;
    padding-top: 30px;
}
.our-product {
  padding-bottom: 0px;
}
.single-features {
  margin-bottom: 0px;
}
}
@media(max-width:575px){
   .newslide-2 h1 {
    font-size: 18px;
}
.category-icons{
  padding:15px;
}
.grid-wrapper{
  --grid-item--min-width:138px;
  --grid-column-count:4;
}
.ml-about-content {
  padding: 30px 0px 30px 0px;
}
.our-text-t210 h2 {
   font-size: 20px;
    padding-bottom: 6px;
    }
.ml-about-content h2 {
font-size: 18px;
    }
    .our-img img {
        width: 100%;
        height: auto;
        /* object-fit: cover; */
    /* }
   
    .common-heading h2 {
        font-size: 19px;
    }
    .our-text-55 h2 {
        font-size: 18px;
    }
     .section-title h2 {
        font-size: 21px;
    }
    .single-features h3 {
        font-size: 18px;
        margin-bottom: 5px;
    }
 
}
@media (max-width: 425px){
 .category-icons .category-item .category-title{
  font-size: 14px;
 }
   section.our-text-69 {
    top: 124px;
}}
@media (max-width: 375px){
     .our-text-t210 h2 {
        font-size: 18px;
        padding-bottom: 13px;
    }
} */ 