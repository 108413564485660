section#contact {
    padding:40px 250px;
    /* background-image: url("../../../assets/images/home/contactus1.png");  */
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    background-position: center;
    position: relative;
  }
  .section_title.mb-4.touct-text {
    text-align: center;
  }
  
  label.form_contact_field {
    text-align: left;
    display: block;
    color: rgb(255, 255, 255) ;
}
  button.main-home {
    background: transparent;
    border: 1px solid #fff;
    padding: 10px 57px;
    color: #fff;
    border-radius: 5px;
  }
  .form-control-1 {
    display: block;
    width: 100%;
    padding: 14px;
    font-size: 14px;
    font-weight: 400;
    color: #fff !important;
    line-height: 1.5;
    border:1px solid #fff;
    border-radius: 10px;
  }
  input.form-control-1.form-control {
    background: transparent !important;
}
textarea.form-control-1.form-control {
  background: transparent !important;

}
  /* .input.form-control-1.form-control:focus {
    border:1px solid #fff;
    box-shadow: none !important;
    color:#fff !important;
    background-color: transparent;
  } */
  .form-control-1::placeholder {
    color: #ffffffb0 ;

  }
  section#contact h2 span {
    font-size: 27px !important;
    font-weight: 500;
    color: #fff;
  }
  p.section_subtitle.mx-auto {
    color: #fff !important;
  }
  @media(max-width:991px){
    section#contact {
         padding: 40px 148px;
     }
   }
  @media(max-width:767px){
   section#contact {
        padding: 38px 0px;
    }
  }
  
  @media(max-width:425px){
    section#contact h2 {
      font-size: 19px;
  }
   section#contact h2 span {
    font-size: 20px !important;
  }
   
  section.how-text {
    padding: 12px 0px 15px;
  }
  }
 
  