section.project-details.bg-light-gray {
    background: #dfe4e9;
    padding: 50px 0px 0px 0px;
}
.caseStudyDetail h2 {
    font-size: 32px;
    padding-bottom: 15px;
}
.caseStudyDetail .tagList {
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
    gap: 15px;
}
.caseStudyDetail .tagList li {
    font-size: 16px;
    padding: 15px 21px;
    background-color: #fff;
    color: #393939;
    line-height: 1.25;
    display: flex;
    align-items: center;
}
ul.platform {
    display: flex;
    padding-left: 0px;
    /* padding: 10px 13px; */
}
 .bannerSec_cntBox .platform li:not(:last-child):after {
    position: absolute;
    top: 50%;
    right: 30px;
    width: 1px;
    height: 20px;
    background-color: rgba(57,57,57,.2);
    content: "";
    transform: translateY(-50%);
}

.icon-web {
    color: rgb(11 46 129);
    font-size: 19px;
    margin: 0px 10px 0px 0px;
}
.icon-ios {
    font-size: 19px;
    margin: 0px 10px 0px 0px;
}
.icon-android{
    font-size: 19px;
    margin: 0px 10px 0px 0px;
}
.black span {
    font-size: 19px;
    margin: 0px 10px 0px 0px;
}

li.green {
    margin: 2px 28px 0px 10px;
}
.project-new-img img {
    width: 100%;
}
section.project-two-section {
    padding: 40px 0px;
}
 .aboutSec_rightBox {
    background-color: #f0f4f7;
    padding: 50px;
}
.aboutSec .diversityList>li {
    padding-left: 13px;
}
.diversityList>li {
    padding-left: 25px;
    position: relative;
    margin-bottom: 14px;
}
.diversityList>li:before {
    width: 4px;
    height: 30px;
    position: absolute;
    top: 3px;
    left: 0;
    content: "";
    background: linear-gradient(135deg,#473a92 0%,#943a71 41%,#eb3f43 100%);
}
span.diversityList_title {
    font-size: 18px;
    font-weight: 500;
}
.project-third {
    background: #000;
    color: #fff;
    text-align: center;
    padding: 50px 0px;
}
.leveragedSec_cnt {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 35px;
    background-color: #202028;
    color: #fff;
}
i.html {
    font-size: 53px;
    color: #ff7600;
}
i.css {
    color: #556bd3;
    font-size: 53px;
}
i.react {
    color: #0dcaf0;
    font-size: 53px;
}
i.bootstrap {
    color: rgb(165 33 181);
    font-size: 53px;
}

i.node {
    color: green;
    font-size: 53px;  
}
i.Mongodb {
    color: #198754;
    font-size: 53px; 
}
.project-laguage {
    margin-bottom: 38px;
}