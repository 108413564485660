.work-category {
  background: #fbf9ed;
  background: linear-gradient(180deg, #fbf9ed 0, #fbeaf8);
  padding: 50px 0px;
  position: relative;
}

.industry-workfor {
  align-items: center;
  cursor: pointer;
  background: #fff;
  border-radius: 8px;
  display: flex;
  margin-top: 10px;
  padding: 15px 20px;
}

.industry-workfor img {
  width: 35px;
  opacity: 70%;
}

.industry-workfor h6 {
  color: rgb(19 47 112);
  font-size: 15px;
  font-weight: 400;
  padding-left: 10px;
  padding-top: 7px;
}

.common-heading h2 {
  font-size: 25px;
  font-size: 26px !important;
  font-weight: 500;
  color: rgb(19 47 112) !important;
}

@media(max-width:1024px) {
  .industries-text-39 p {
    font-size: 13px !important;
  }
}

@media (max-width: 767px) {
  .indus-box-right {
    margin-top: 20px;
  }
}

@media (max-width: 430px) {
.industry-workfor {
  padding: 15px 5px !important;
}
}


@media (max-width: 425px) {
  h2 strong {
    font-size: 23px;
  }

  .common-heading h2 {
    font-size: 22px !important;
  }

  .work-category {
    padding: 30px 0px;
  }

  .industry-workfor {
    display: block;
    padding: 5px 20px;
    text-align: center;
  }

  .industry-workfor h6 {
    font-size: 12px;
    padding-top: 2px;
  }
}

@media (max-width: 375px) {
.industry-workfor {
  padding: 15px 9px;
}
}