
button.lnk.btn-main.bg-btn.btn.btn-primary {
    color: #fff;
    top: 0px;
    position: absolute;
    right: 19%;
    border: 1px solid #00adb8;
    border-radius: 0px 101px 100px 0px;
    padding: 13px 25px;
    background: #00adb8;
  }
  a.Footer_link {
    color: #000 !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    padding: 0px 6px;
   
  }
    img.footer__image {
      width: 100%;
      position: relative;
  }
    .footer__logo {
      max-width: 100%;
      margin: 0 auto;
      margin-bottom: 0px;
    }
    .footer__desc {
      max-width: 800px;
      padding-bottom: 12px !important;
      margin: 0 auto;
      border-bottom: 1px solid #e1dddd;
      margin-bottom: 15px;
    }
    .social ul {
      display: flex;
      padding-left: 0px;
      margin-bottom: 0px;
      /* text-align: center; */
      justify-content: center;
      padding-top: 8px;
  }
  .social ul li img {
    width: 25px;
    height: 25px;
    margin: 0px 4px 0px 4px;
    /* padding: 0px 10px; */
  }
    .sus-text {
      padding-top: 30px;
  }
    .footer__copyright {
      padding-top: 8px !important;
      text-align: center;
    }
    .emai-text h2 {
      font-size: 23px;
      font-weight: 700;
      margin-bottom: 0px;
  }
  
  
    @media(max-width:425px){
      .footer__wrapper {
        padding: 15px 0px;
    }
    .footer__wrapper {
     
      padding: 5px;
  
  }
 
    }
.email-subs form {
    position: relative;
    top: 169px;
  }
  .emai-text p {
    margin-bottom: 0px;
    margin-top: 6px;
  }
  .emai-text h2 {
    font-size: 24px;
    font-weight: 500;
    color: rgb(19 47 112);
    margin-bottom: 0px;
  }
  .email-subs {
    position: relative;
    top: 15px;
  }
  .email-subs img {
    width: 100%;
  }
  .newsletter-text {
    margin-top: 19%;
  }
  .email-subs input {
    padding: 0px 0px 0px 0px;
    display: block;
    width: 100%;
    height: 52px;
    color: #000;
    border: none;
    outline: 0;
    background-color: #f1f0ec;
    border-radius: 30px;
    padding-left: 20px;
  }
  button.lnk.btn-main.bg-btn.btn.btn-primary {
    color: #fff;
    top: 0px;
    position: absolute;
    right: 0px;
    border: 1px solid #596570 !important;
    border-radius: 0px 101px 100px 0px;
    padding: 13px 25px;
    background: #596570 !important;
  }
  h6.suscription-text {
    padding-top: 23px;
    color: #022d62;
  }
  img.footer__image {
    width: 100%;
    position: relative;
  }
  .footer__desc {
    max-width: 651px;
    padding-bottom: 12px !important;
    margin: 0 auto;
    border-bottom: 1px solid #e1dddd;
    margin-bottom: 15px;
  }

  @media (max-width: 767px) {
    button.lnk.btn-main.bg-btn.btn.btn-primary {
      right: 0px;
      padding: 13px 16px;
    }
    .newsletter-text {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
  @media (max-width: 575px) {
    .email-subs input {
      font-size: 13px;
      padding-left: 18px;
    }
    .newsletter-text {
      margin-top: 29px;
    }
    .email-subs {
      top: 8px;
    }
  }
  @media (max-width: 425px) {
    .emai-text h2 {
      font-size: 18px;
    }
    button.lnk.btn-main.bg-btn.btn.btn-primary {
      right: -2px;
      padding: 15px 16px;
      font-size: 13px;
    }
  }
  @media (max-width: 320px) {
    .email-subs input {
      height: 53px;
    }
    button.lnk.btn-main.bg-btn.btn.btn-primary {
      padding: 16px 16px;
    }
  }