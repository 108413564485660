.details button.btn.btn-primary {
    background: transparent;
    color: #fff;
    border-radius: 23px;
    border: 1px solid #fff;
    width: 42px;
    float: right;
    height: 42px;
  }
  .portfolio-1 {
    margin-bottom: 22px;
  }

  /* Craeted refering to LittleSnippets.net Pen: https://codepen.io/littlesnippets/pen/adLELd */
  .frame {
    text-align: center;
    position: relative;
    cursor: pointer;
    perspective: 500px;
  }
  .frame img {
    width: 100%;
    height: 100%;
  }
  .frame .details {
    width: 100%;
    height: 100%;
    padding: 50px 20px;
    padding: 94px 20px 0px;
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotateY(90deg);
    transform-origin: 50%;
    background: #212529a6;
    opacity: 0;
    transition: all 0.4s ease-in;
    background: rgb(0, 173, 184);
  }
  .frame:hover .details {
    transform: translate(-50%, -50%) rotateY(0deg);
    opacity: 100%;
  }
  
  .details h1 {
    color: #fff;
    font-size: 22px;
    font-weight: 600;
  }
  .details p {
    color: #fff !important;
    font-size: 17px !important;
    padding-bottom: 15px !important;
  }
  .portfolio-page:before {
    content: "";
    background-color: rgb(0 0 0 / 55%);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
a.all-read-more {
  /* padding-top: 54px; */
  color: #ffc107;
  font-size: 15px;
  font-weight: 600;
}
  .allproject-heding h2 {
    font-size: 28px;
    padding-top: 16px;
    font-weight: 600;
    margin-bottom: 0px;
    color:#fff ;
    position: relative;
  }
  .portfolio-1 {
    margin-bottom: 10px;
  }
 
  .filter-menu {
    float: right;
    position: relative;
  }
  button.btn65,
  button.btn66 {
    border-radius: 40px 40px 40px 20px;
    cursor: pointer;
    display: inline-block;
    padding: 14px 16px;
  }
  .descriptions-p h4 {
    font-size: 18px;
}

  button.btn65 {
    background: #f95b94;
    border: none;
    color: #fff;
    margin-left: 10px;
  }
  button.btn66 {
    background: #feeee3;
    border: none;
    color: #000;
    margin-left: 7px;
  }
  .bg-salmon {
    background-color: salmon;
    color: white;
  }
 
  .item-image img.portfolio {
    width: 100%;
    border-radius: 12px;
  }
  .item-image {
    border-radius: 17px;
    padding: 1px 2px;
    background-color: #fff;
    border: 1px dashed #ccc;
    box-shadow: 0 2px 48px 0 rgb(0 0 0 / 8%);
}
  @media (max-width: 991px) {
    .portfolio-1 {
      margin-bottom: 0px;
    }
    .filter-menu {
      float: left;
    }
 
    .common-heading.pp h2 {
      font-size: 24px;
      padding-top: 0px;
      margin-bottom: 16px;
    }
  }

  @media (max-width: 435px) {
    button.btn65,
    button.btn66 {
      padding: 16px;
      font-size: 13px;
    }

    .common-heading.pp h2 {
      font-size: 19px;
      margin-bottom: 10px;
    }
  }
  @media (max-width: 359px) {
    button.btn66 {
      padding: 14px;
    }
  }
  @media (max-width: 358px) {
    .details h1 {
      color: #fff;
      font-size: 19px;
      font-weight: 700;
    }
    .frame .details {
      padding: 67px 20px 0px;
    }
    .details p {
      font-size: 14px !important;
    }
  }
  @media (max-width: 347px) {
    button.btn65,
    button.btn66 {
      padding: 12px;
      font-size: 13px;
    }
  }

  @media (max-width: 322px) {
    .frame .details {
      padding: 42px 20px 0px;
    }
  }
  @media (max-width: 327px) {
    button.btn65,
    button.btn66 {
      padding: 9px;
      font-size: 12px;
    }
  }