section.delevery-text {
  position: relative;
  background-image: url("../../assets/images/indusry/impetrosys1.png");
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-bottom: 50px;
  padding-top: 50px;
}
section.industries-0 {
  position: relative;
  background-image: url("../../assets/images/ind.jpg");
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-bottom: 70px;
  padding-top: 70px;
}
.circle {
  position: relative;
  top: 61px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70px;
  height: 70px;
  background: #00adb8;
  color: white;
  text-align: center;
  line-height: 80px;
  border-radius: 50%;
  font-size: 45px;
}

.circle::after,
.circle::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 70px;
  height: 70px;
  background: #00adb8;
  border-radius: 50%;
  z-index: -1;
  animation: grow 1s ease-in-out infinite;
}
/* &::after {
      background: #00adb8; */
.circle::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 70px;
  height: 70px;

  border-radius: 50%;
  z-index: -1;
  animation: grow 1s ease-in-out infinite;
}

.circle::before {
  background: #00adb8;
  animation-delay: -0.4s;
}

@keyframes grow {
  0% {
    transform: scale(1, 1);
    opacity: 1;
  }
  100% {
    transform: scale(1.8, 1.8);
    opacity: 0;
  }
}
section.industries-0:before {
  content: "";
  background: rgb(0 0 0 / 68%);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}
.ind-text-34 ul {
  padding-left: 0px;
}
.ind-text-35 ul {
  padding-left: 0px;
}

.industries-text-39 h5 {
  color: #132f70 !important;
}
.ind-text-5 h2 {
  color: rgb(19 47 112);
  font-size: 27px;
  font-weight: 500;
}
.ind-text-31 h2 {
  color: rgb(19 47 112);
  font-size: 27px;
  font-weight: 500;
}
.indtext-0.text-center.text-white h2 {
  font-size: 32px;
  font-weight: 600;
}
.industries-00 {
  background: #f0ffff1a;
  padding: 70px 50px;
  position: relative;
}
.ind-video i {
  position: absolute;
  font-size: 43px;
  color: #fff;
  left: 2px;
  top: -4px;
}
.indtext-0 h2 {
  font-size: 28px;
  color: #fff;
  font-weight: 700;
}
.ind-video {
  position: relative;
  background: transparent;
  width: 50px;
  text-align: center;
  display: flex;
  height: 50px;
  border-radius: 50%;
  margin: 0 auto;
  border: 2px solid #fff;
}
section.ind-7 {
  text-align: center;
  position: relative;
  padding: 50px 0px 30px;
}
.ind-text-30 p {
  text-align: center;
}
.ind-text-32.new {
  background-color: #f9c9c8a6;
}
.ind-text-32.new1 {
  background-color: #fcf0d59e;
}
.ind-text-34 ul li:before {
  content: "✓";
  color: #10cdd9;
  font-weight: 700;
  font-size: 21px;
  padding-right: 10px;
}
.ind-text-35 ul li:before {
  content: "✓";
  color: #10cdd9;
  font-weight: 700;
  font-size: 21px;
  padding-right: 10px;
}

.ind-text-32 {
  margin-bottom: 7px;
  background-color: #bae3c8c4;
  box-shadow: 0 2px 48px 0 rgb(0 0 0 / 8%);
  padding: 14px;
  border-radius: 20px 0px 20px 0px;
}
.ind-text-32 h1 {
  font-size: 21px;
  font-weight: 500;
  color: #000;
  margin-bottom: 0px;
}

.ind-text-34 li {
  font-size: 14px;
  list-style-type: disclosure-closed;
  padding-bottom: 7px;
}
.ind-text-35 li {
  font-size: 14px;
  list-style-type: disclosure-closed;
  padding-bottom: 7px;
}
.feature-info-icon {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 34px;
  cursor: pointer;
}

.feature-info-icon i {
  background: #00adb8;
  font-size: 58px;
  color: #fff;
  width: 120px;
  height: 120px;
  position: relative;
  border-radius: 50%;
  padding-top: 10px;
  transition: all 0.2s ease-in-out 0s;
}
.feature-info-icon i:hover {
  background: rgb(19 47 112);
  color: #fff;
}

@media (max-width: 575px) {
  .industries-00 {
    padding: 19px 20px;
  }
  section.industries-0 {
    padding-bottom: 30px;
    padding-top: 30px;
  }
  .indtext-0 h2 {
    font-size: 21px;
  }
  .circle::after,
  .circle::before {
    width: 40px;
    height: 40px;
  }
  .circle {
    width: 40px;
    height: 40px;
    font-size: 37px;
    line-height: 49px;
    top: 32px;
  }
  .ind-text-5 h2 {
    font-size: 21px;
  }
  .ind-text-31 h2 {
    font-size: 22px;
  }
  section.delevery-text {
    padding-bottom: 25px;
    padding-top: 25px;
  }
}

@media (max-width: 991px) {
  .industry-workfor {
    padding: 15px 15px;
  }

  .industry-workfor h6 {
    font-size: 12px;
    padding-top: 8px;
  }
}



@media (max-width: 1024px) {
  .industry-workfor {
    padding: 15px 15px;
  }

  .industry-workfor h6 {
    font-size: 12px;   
}
}
