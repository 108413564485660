
section.product {
    position: relative;
    padding: 32px;
    background-color: #ede7e7;
}
.product-t1 {
    text-align: center;
}
.product-title h2 {
    font-size: 28px;
    padding: 8px;
}

.product-title p {
    padding-top: 10px !important;
    width: 500px;
    margin: auto;
}
section.product-1 {
    margin-top: 20px;
}
.product-text-3333 ul {
    padding-left: 0px;
}
 .product-text-3333 li {
    padding-bottom: 10px;
 }
.product-text-3333 span {
    color: #6084a4 !important;
    font-size: 14px !important;
    /* padding: 0px !important; */
    padding-left: 10px;
}
.product-im1 img {
    width: 100%;
}
.product-text-60 {
    padding-top: 40px;
}
.product-2 img {
    width: 100%;
}
@media(max-width:991px){
    .produttesting h3 {
        padding-top: 16px;
    }
}

@media(max-width:767px){
    .product-2 .offset-2 {
        margin-left: 0px !important;
    }
    .product-text-60 h6 {
        line-height: 29px !important;
    }
    .product-text-60 {
        padding-top: 0px;
    }
    section.product-1 {
        margin-top: 121px;
    }
}
@media(max-width:575px){
      .product-title h2 {
        font-size: 19px;
    }
    .product-title p {
        width: 100%;
    }
    .product-2 h3 {
        font-size: 17px;
    }
    .product-i1 h2 {
        font-size: 17px;
    }
     .product-text-60 h6 {
        line-height: 24px !important;
        font-size: 13px;
    }
    h3.mb-4.product-5pro {
        font-size: 16px;
        margin-bottom: 10px !important;
    }
    .producttesting1 h5 {
        font-size: 16px;
    }
    .producttesting2 h5 {
        font-size: 15px;
    }
    .producttesting3 h2 {
        font-size: 16px;
    }
}