.career-img101 img {
  width: 100%;
}
img.career-img101 {
  width: 100%;
}

.career-title-box {
    height: 110px;
  }
  
  ul.course-meta-details.list-inline.w-100 p {
    font-size: 12px !important;
    font-weight: 500;
  }
  
.Carees-heading h6 {
  display: inline-block;
  color: rgb(0, 0, 0);
  background-color: rgb(248, 237, 253);
  border-radius: 30px;
  padding: 5px 20px 4px;
  font-size: 13px;
  font-weight: 400;
}
.Carees-heading h2 {
  font-size: 27px;
  font-weight: 500;
  color: rgb(19 47 112);
  margin-bottom: 0px;
  padding-top: 4px;
}
section.Career-second {
  padding: 40px 0px 30px 0px;
}
.carres-second-head h2 {
  font-size: 26px;
}
.carres-second-head .bar {
  margin: 0px;
}
section.career-third {
  padding: 50px 0px;
}
.Careers-forth ul li {
  position: relative;
  padding-left: 0px;
  font-size: 15px;
  color: #53565a;
  margin-bottom: 5px;
}

.Careers-forth ul li::after {
  width: 10px;
  height: 10px;
  background-color: rgb(0, 173, 184);
  left: -27px;
  content: "";
  border-radius: 50%;
  position: absolute;
  top: 10px;
}
section.jointalentCommunity {
  padding: 10px 0px 18px 0px;
  background-color: #f0f1f352;
}
.career-tt11.mt-5 {
  padding: 18px 151px 20px 20px;
}
.career-tt11 h2 {
  font-size: 30px;
  font-weight: 500;
  color: #000;
}
/* Vacancy */

.vacancy {
  background: #faf0e499;
  padding: 30px 0px 75px 0px;
}
a.btn.btn-primary.stretched-link {
  margin: 0 auto;
  /* text-align: center; */
  background: transparent;
  color: #58a9a7;
  border: 1px solid #58a9a7;
  border-radius: 20px;
  font-size: 14px;
  /* padding: 5px 30px; */
  font-weight: 600;
  display: block;
  width: 100px;
}
span.badge.bg-secondary {
  color: #58a9a7;
  padding: 11px 20px;
  font-size: 12px;
  /* background: #dfa1a1 !important; */
  margin: 0px 6px 6px 0px;
  background-color: #f1f7f5 !important;
}
.pagination {
  float: right;
  padding-top: 16px;
}
.active > .page-link,
.page-link.active {
  background-color: rgb(0, 173, 184);
  border-color: rgb(0, 173, 184);
  color: #fff !important;
}
.page-link {
  color: #000 !important;
}
.page-link {
  border-radius: 20px;
  color: #000 !important;
  margin: 0px 5px;
}

.services-item {
  min-height: 410px;
  background: #fff;
  box-shadow: 0 0 29px 0 rgb(68 88 144 / 12%);
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  z-index: 1;
  border: 1px dashed #ccc;
  /* border: 1px solid #eaeaeb; */
  /* padding: 23px; */
  text-align: left;
  margin: 20px 0 0;
  /* background: #070427; */
  color: #000;
}
.services-text {
  padding: 15px 20px 20px 20px;
}
.services-text h3.title {
  margin: 10px 0px 2px 0px;
  font-weight: 500;
  font-size: 20px;
  /* height: 20px; */
  /* display: flex; */
  color: rgb(19 47 112);
  /* align-items: center; */
}
.job-portal {
  padding: 10px 0px;
  height: 140px;
}
/* .course-meta-details {
    border-top: 1px solid #eee;
    padding-top: 9px;
    padding-bottom: 0px;
} */

.course-meta-details {
  border-top: 1px solid #eee;
  padding-top: 9px !important;
  /* padding-bottom: 0px; */
  height: 84px;
}

/* .list-inline {
    margin-left: -5px;
} */

.list-inline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
}

/* .course-meta-details li {
    border-right: 1px solid #cccccc;
    display: table-cell !important;
    text-align: center;
    padding-left: 16px;
    padding-right: 16px;
} */

.course-meta-details li {
  border-right: 1px solid #cccccc;
  /* display: table-cell !important; */
  /* flex: 1 1 0 !important; */
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}
.card {
  box-shadow: 0 20px 27px 0 rgb(0 0 0 / 5%);
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 1rem;
}

.card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.5rem 1.5rem;
}
.avatar-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #000;
  color: #fff;
  font-weight: 700;
}

.avatar {
  width: 3rem;
  height: 3rem;
}
.rounded-3 {
  border-radius: 0.5rem !important;
}


@media (max-width: 1199px) {
.course-meta-details li {
  padding-left: 4px;
  padding-right: 4px;
}
ul.course-meta-details.list-inline.w-100 p {
  font-size: 10px !important;
}
.career-title-box {
  height: 126px;
}
}

@media (max-width: 991px) {
  section.Carees-main {
    padding-top: 20px;
  }
  img.img-fluid.cree-image-team.aos-init.aos-animate {
    margin-top: 60px;
}
}

@media (max-width: 767px) {
  .career-tt11 {
    margin-top: 0px !important;
  }
  .job-portal {
    height: 120px;
  }
  .career-title-box {
    height: 130px;
}
  .career-tt11.mt-5 {
    padding: 0px 0px 0px 0px;
  }
  section.jointalentCommunity {
    padding: 30px 0px 30px 0px;
  }
  section.career-third {
    padding: 30px 0px;
  }
  .Careers-forth {
    margin-top: 14px;
  }
  section.Career-second {
    padding: 30px 0px 30px 0px;
  }

  .course-meta-details li {
    padding-left: 45px;
    padding-right: 45px;
}
}


@media (max-width: 575px) {
img.img-fluid.cree-image-team.aos-init.aos-animate {
  margin-left: 80px;
  width: 70%;
}

}



@media (max-width: 539px) {
.course-meta-details li {
  padding-left: 40px;
  padding-right: 40px;
}

}

@media (max-width: 430px) {
img.img-fluid.cree-image-team.aos-init.aos-animate {
  margin-left: 66px;
  width: 65%;
  margin-top: 15px;
}
.course-meta-details li {
  padding-left: 24px;
  padding-right: 24px;
}
}

@media (max-width: 425px) {
    
  img.img-fluid.cree-image-team.aos-init.aos-animate {
    margin: 10px 30px 0px 55px;
}
  .Carees-heading h2 {
    font-size: 21px;
  }
  .carres-second-head h2 {
    font-size: 21px;
  }
  .career-tt11.mt-5 h2 {
    font-size: 21px;
  }
  .course-meta-details li {
    padding-left: 20px;
    padding-right: 20px;
}
}

@media (max-width: 400px) {
.job-portal {
  height: 140px;
}
}

@media (max-width: 375) {
    .job-portal {
        height: 133px !important;
    }
    img.img-fluid.cree-image-team.aos-init.aos-animate {
      margin: 10px 30px 0px 44px;
  }
}










