section.how-text {
  padding: 50px 0px;
}

.wor-img {
  margin-top: 40px;
}
.wor-img img {
  width: 82%;
}
section.how-text .bar {
  margin: 8px auto;
}
.work {
  text-align: center;
}
section.how-text h2 {
  font-size: 26px;
  font-weight: 500;
  color: rgb(19 47 112);
}
.work-steps-detail .steps-text h4 {
  margin: 3px 0 0px;
  color: #000;
  font-size: 21px !important;
  font-weight: 400;
}
.work-steps-detail .steps-text .step-desc {
  padding-top: 7px !important;
  margin-bottom: 0;
  text-align: inherit;
}
.work-steps-detail .steps-icon {
  margin-right: 20px;
}
.work-steps-box {
  margin-top: 50px;
}
.work-steps-detail .steps-icon i {
  width: 50px;
  height: 50px;
  box-shadow: 0.6em 0.6em 1.2em #d2dce9, -0.5em -0.5em 1em #ffffff;
  padding: 12px 12px 12px 12px;
  display: flex;
  border-radius: 50px;
  font-size: 26px;
  background: #00adb8;
  color: #fff;
}
.work-steps-detail {
  display: flex;
}
@media (max-width: 767px) {
  /* .wor-img img {
    width: 70%;
    margin: 65px;
} */
  .wor-img {
    margin-top: 0px;
  }

  .wor-img img {
    width: 70%;
    margin: 30px 0px 0px 100px;
  }
}

@media (max-width: 575) {
  .wor-img {
    margin-top: 0px;
  }
  .wor-img img {
    margin: 30px 0px 0px 88px;
  }
}

@media (max-width: 430px) {
  .wor-img img {
    width: 70%;
    margin: 25px 0px 0px 70px;
  }
  section.how-text {
    padding: 34px 0px;
}
}

@media (max-width: 425px) {
  .wor-img img {
    margin: 15px 0px 0px 66px;
  }

  .work-steps-detail .steps-text h4 {
    font-size: 18px !important;
  }
  section.how-text h2 {
    font-size: 20px !important;
  }
  section.how-text p {
    padding-top: 5px !important;
  }
}
@media (max-width: 375px) {
  .wor-img img {
    margin: 14px 57px 0px 56px;
  }
}
