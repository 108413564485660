@import url(https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i);

body,html {
  height: 100%;
  width: 100%;
  font-family: "Poppins", sans-serif !important;
  padding: 0;
  margin: 0;
  background-color: #fff;
}
body > iframe[style*='2147483647']{
  display: none;
}
 .form-control {
  margin-top: 8px;
}

/*------------global-----------*/
a {
  text-decoration: none !important;
}
li {
  list-style-type: none !important;
}
/* p {
  color: #0000009e !important;
  font-size: 14px !important;
  padding: 0px !important;
  margin-bottom: 0px !important;
  line-height: 29px;
} */


p {
  color: #0000009e !important;
  font-weight: 400;
  font-size: 14px !important;
  padding: 0px !important;
  margin-bottom: 0px !important;
  line-height: 29px;
}

p.errormessage {
  font-size: 12px !important;
  padding-top: 5px !important;
  color: #d51616 !important;
  text-align: left;
  margin-bottom: 0px;
}
/* .ml-main-section {
  position: relative;
  padding-top: 130px;
  padding-bottom: 400px;
  width: 100%;
  background-image: url("./assets/images/home/homeslid1.png");
   background-position: 100% 100%;
  background-size: cover;
  background-repeat: no-repeat;
} */
/* .ml-main-section1 {
  position: relative;
  padding-top: 130px;
  padding-bottom: 400px;
  width: 100%;
  background-image: url("./assets/images/home/homeslide.png");
   background-position: 100% 100%;
  background-size: cover;
  background-repeat: no-repeat;
} */
/* .ml-main-section2 {
  
  width: 100%;
  background-image: url("./assets/images/home/slider3.png");
   background-position: 100% 100%;
  background-size: cover;
  background-repeat: no-repeat;
} */


/* .top-img-silder-1 img {
  width: 100%;

  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
} */

.about-area {
  background: url("./assets/images/home/Flexbleloan.png");
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 40px 0px 20px 0px;
  position: relative;
}

.swal2-styled.swal2-confirm {
  border:none !important;
  border: 1px solid #00adb8 !important;
  border-radius: 0px !important;
  background: initial;
  background-color: #00adb8 !important;
  color: #fff;
  font-size: 1em;
}
.swal2-title {
  padding: 0px !important;
  color: #063b70 !important;
  font-size: 28px !important;
  font-weight: 500 !important;
  padding-top: 0px !important;
  padding-bottom: 5px !important;
}
.swal2-html-container {
  margin: 0px 16px !important;
  color:rgb(19, 47, 112) !important;
  font-size: 14px !important;
}
.swal2-actions {
  margin: 6px !important;
}
.banner-slide{
     background-image: url("./assets/images/banner-request.png"); 
     width: 100%;
     background-repeat: no-repeat;
     background-size: cover;
     text-align: center;
     background-position: center;
     color: #fff;
     padding: 100px 0px;
     position: relative;
}
.contacus{
  background-image: url("./assets/images/home/contactus1.png"); 
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  background-position: center;
  position: relative;

}

  .request-form {
    background-image: url("./assets/images/reuest.jpg");
    padding: 65px 0px 70px 0px;
    width: 100%;
    height: 100%;
    text-align: left;
    background-position: center;
    color: #fff;
    position: relative;
    background-repeat: no-repeat;
    margin-bottom: -23px;
}
.footer__wrapper {
  position: relative;
  background-image: url("./assets/images/footer/footer.png") !important;
  text-align: center;
  background-color: #f7fafd;
  padding: 29px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.differentiatorSec {
  padding: 50px 0;
  background: #e4e7eb url("./assets/images/service1.png");
  background-size: cover;
  background-position: right top;
  background-repeat: no-repeat;
  position: relative;
}

section.updated.stack{
  background: url("./assets/images/home/Flexbleloan1.png");
  background-size: 100%;
  background-repeat: no-repeat;
  position: relative;
}
section.updated.stack.contact{
  background: url("./assets/images/home/Flexbleloan1.png");
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 40px 0px;
  background-color: #f3f9fc;
  position: relative;
}
.section-team{
  background: url("./assets/images/home/Flexbleloan.png");
  background-size: 100%;
  background-repeat: no-repeat;
  position: relative;
  margin-top: 65px;
}
section.Clients.Here{
  background: url("./assets/images/home/Flexbleloan.png");
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 95px 0px;
  position: relative;
}

.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: #11a683;
  border-color: #11a683;
}

.services-t1{
  position: relative;
  background-image: url("./assets/images/services.jpg"); 
 width: 100%;
 background-repeat: no-repeat;
 background-size: cover;
 background-position: center; 
 padding: 80px 0px;
}
section.newslide{
  position: relative;
   background-image: url("./assets/images/support1.jpg"); 
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;  
}
.testimonial-main {
   padding: 50px 0px;
  position: relative;
  color: #fff;
  background: url(./assets/images/testimonial-bg.png) no-repeat center center;
  background-attachment: fixed;
  width: 100%;
}

.portfolio-page {
 
  background-image: url("./assets/images/aboutarea11.png");
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  padding: 24px 0px 40px 0px;
}
.newslide::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #00000094;
 
}
button.main-btn.btn.btn-primary {
  color: #000 !important;
  margin: 0px 0px;
  width: 148px;
  height: 40px;
  border: none;
  font-weight: 500 !important;
  font-size: 14px !important;
  border-radius: 20px;
  background-color: #ffa500;
  padding: 0px 0px 0px 0px;
}
button.main-btn.btn.btn-primary a {
  color: #000;
}
.bar {
  height: 5px;
  width: 90px;
  background: #93cbcf7a;
  margin: auto;
  position: relative;
  border-radius: 30px;
}
 .bar:before {
  content: "";
  position: absolute;
  left: 0;
  top: -2.7px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: #30cfcb !important;
  animation-name: roll;
  animation-duration: 5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-delay: 2s;
}

@keyframes roll {
  100% {transform: translateX(80px) rotate(2turn);}
  
}
.shadow {
  box-shadow: 0 5px 25px -2px rgb(0 0 0 / 6%);
  background-color: #fff;
}
.swiper-button-prev:after, .swiper-button-next:after {
  font-size: 17px !important;
  color: #00adb8;
}
p.help.is-danger {
  color: red !important;
  font-size: 12px !important;
  padding-top: 10px !important;
}
body::-webkit-scrollbar
{
	width: 5px;
  border-radius: 10px;
	background-color: #fff;
  height: 100%;
}
body::-webkit-scrollbar-thumb
{
  width: 2px;
  height: 1px;
	border-radius: 10px;
	box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #00adb8;
}

@media(max-width:1199px){
p {
  line-height: 24px;
}
}


@media(max-width:480px){
  .ml-main-section2 {
    padding-bottom: 244px;
}
.ml-main-section{
  padding-bottom: 244px;
}
.ml-main-section1{
  padding-bottom: 244px;
}
}

@media(max-width:425px){
  .services-t1 {
    padding: 39px 0px;
}

}
@keyframes fadeInleft {
  from {
    opacity: 0;
    transform: translateX(300px);
  }
  to {
    opacity: 1;
  }
}