section.section-ptb.bg-secondary {
    background-color:#f8f9fa!important;
  }

section.service-provide {
  background-color: #0E1E2A;
  padding: 50px 0px;
  background-image: url("../../assets/images/services2.png");
  background-position: center right;
  background-repeat: no-repeat;
} 
  .service-text-223 {
    padding-left: 49px;
  }
  .restly-about-dec ul li {
    color: #fff;
    line-height: 24px;
    font-weight: 400;
    display: inline-block;
    width: 49%;
    position: relative;
    margin-bottom: 25px;
    clear: both;
    padding-left: 45px;
  }
  .restly-about-dec ul li:before {
    content: "\f00c";
    font-family: 'Font Awesome 5 Free';
    width: 35px;
    height: 35px;
    display: inline-block;
    background: #104cba;
    border-radius: 100%;
    font-size: 14px;
    text-align: center;
    line-height: 40px;
    font-weight: 600;
    clear: both;
    position: absolute;
    left: 0;
  }
  .restly-about-dec ul {
    padding-left: 0px;
    padding-top: 40px;
}
  .services-t1 .section-title {
    text-align: inherit;
  }
  section.section-ptb.bg-secondary .section-title{
    padding-top: 21px;
  }
  .services-t1:before {
    content: "";
    background-color: rgb(0 0 0 / 38%);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  .feature-timeline {
    display: flex;
    padding-bottom: 0px;
    position: relative;
  }
  .feature-timeline:before {
    background: #022d6226;
    content: "";
    height: 100%;
    left: 26px;
    position: absolute;
    top: 0;
    width: 2px;
  }
  .feature-timeline .feature-info-number .feature-timeline-number {
    align-items: center;
    background: linear-gradient(to right top,#d5fefd,#e0fdff,#effbff,#adb5bd,#6c757d);
    border-radius: 50%;
    color: #022d62;
    display: flex;
    font-size: 21px;
    font-weight: 700;
    height: 50px;
    justify-content: center;
    margin-right: 25px;
    position: relative;
    transition: all .3s ease-in-out;
    width: 50px;
    margin-top: 18px;
  }
  
  h5.feature-timeline-title{
    color: #022d62 !important;
  }
  .feature-timeline .feature-info-number .feature-timeline-number:after {
    border: 6px solid transparent;
    border-left-color: #adb5bd;
    content: " ";
    height: 0;
    left: 100%;
    margin-left: -1px;
    margin-top: -6px;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transition: all .3s ease-in-out;
    width: 0;
  }
  .feature-timeline .feature-info-content {
    margin-top: 28px;
  }
  .feature-timeline .feature-info-content .feature-timeline-title {
    font-weight: 500;
    line-height: 22px;
  }
  .section-title {
    text-align: center;
  }
  .services-service-new {
    display: none;
  }
   .section-title h2 {
    margin-bottom: 0;
    text-transform: capitalize;
    font-size: 29px;
    font-weight: 600;
  }

  .service-new-bar .bar {
    margin: 10px auto !important;
    height: 5px;
    width: 90px;
    background: #93cbcf7a;
    position: relative;
    border-radius: 30px;
  }
  .section-title .bar {
    height: 5px;
    width: 90px;
    background: #93cbcf7a;
    margin: 10px 0px;
    position: relative;
    border-radius: 30px;
  }
  .single-solutions-box {
    padding: 20px 20px;
    cursor: pointer;
    text-align: center;
    transition: .5s;
    position: relative;
    margin-top: 20px;
    z-index: 1;
    border: 1px solid #c5fffd;
    border-radius: 5px;
}
  .single-solutions-box:hover {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    -webkit-box-shadow: 0 2px 48px 0 rgb(0 0 0 / 8%);
    box-shadow: 0 2px 48px 0 rgb(0 0 0 / 8%);
  }
  .single-solutions-box:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    border-radius: 5px;
    height: 100%;
    background: linear-gradient(to right top,#d5fefd,#e0fdff,#effbff,#fbfaff,#fffcff);
  }
  .single-solutions-box .icon {
    margin-bottom: 25px;
    position: relative;
    z-index: 1;
    display: inline-block;
  }
  .single-solutions-box .icon:before {
    content: "";
    position: absolute;
    left: -5px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: -1;
    background: #f0fdfe;
    -webkit-box-shadow: 0 2px 48px 0 rgb(0 0 0 / 9%);
    box-shadow: 0 2px 48px 0 rgb(0 0 0 / 9%);
    border-radius: 30% 70% 70% 30%/40% 60% 40% 60%;
    width: 60px;
    height: 60px;
  }
  .single-solutions-box:hover .icon img {
    -webkit-transform: rotateY(-1turn);
    transform: rotateY(-1turn);
  }
  .section-title h5 {
    font-size: 25px !important;
    color: rgb(19 47 112);
    font-weight: 500;
  }
  .single-solutions-box .icon img {
    -webkit-transition: .5s;
    transition: .5s;
  }
  
  .single-solutions-box h3 a {
    color: #000;
    display: inline-block;
    font-size: 19px;
    font-weight: 400;
  }
  
  .services-0 img {
    width: 100%;
    padding-top: 85px;
}
  
  .common-heading.pp {
  margin-bottom: 8%;
  }
  .single-solutions-box .learn-more-btn {
  color: #365267;
  font-size: 14px;
  font-weight: 400;
  }
  h2.commonTitle__title.text-dark {
    color: rgb(19 47 112) !important;
    font-size: 27px;
      font-weight: 500;
  }
  .service-img- img {
  width: 100%;
  }
  .service-text-22 h6 {
    color: #ffc107db;
  }
  .service-text-22 p {
    color: #fff !important;
  }
  .service-text-22 h1 {
  font-weight: 700;
  font-size: 29px;
  color:#fff;
  }
  .icon.icon-size {
    margin-bottom: 36px;
}
  .diffrent p {
  line-height: 32px;
  max-width: 934px;
  font-size: 15px !important;
  }
  @media(max-width:1200px){
  .single-solutions-box p {
    font-size: 14px !important;
}
  }


  @media(max-width:1024px){
  .single-solutions-box {
    padding: 20px 60px;
    cursor: pointer;
    text-align: center;
    transition: .5s;
    position: relative;
    margin-top: 20px;
    z-index: 1;
    border: 1px solid #c5fffd;
    border-radius: 5px;
}
.icon.icon-size {
  margin-bottom: 36px;
}
  }


  
  @media(max-width:991px){
  .services-0 img {
      padding-top: 0px;
  }

  .single-solutions-box {
    padding: 20px 8px;
}
}
  @media(max-width:767px){
    .services-0 img {
      width: 75%;
      margin: 40px;
  }
  .bg-secondary {
      padding-bottom: 13px;
  }
  .single-solutions-box h3 a {
    font-size: 18px;
}
  .service-text-223 {
    padding-left: 0px;
    padding-top: 20px;
  }
  /* .differentiatorSec{
    20px 0px 0px 0px !important;
  } */
  section.service-provide {
    padding: 30px 0px 0px 0px;
  }
 
     .services-service-new{
      display: block;
     }
     .services-t1 p {
      width: 100% !important;
  }
    /* .single-solutions-box{
      padding:24px 59px 40px;
    } */
    .single-solutions-box .icon {
      margin-bottom: 4px;
  }
  .services-2.mt-5.mb-4 {
    display: none;
  }
  .services-2.mt-3.mb-3 {
    display: none;
  }
  
  
  
  }
  @media (max-width: 575px){
    .feature-timeline .feature-info-content .feature-timeline-title {
      font-weight: 500;
      font-size: 17px !important;
  }
  .differentiatorSec {
    padding: 20px 0px !important;
}
   .service-img- img {
    width: 80%;
    text-align: center;
    margin: auto;
    display: block;
  }
  .service-text-22 {
    margin-top: -22px;
  }
  }
  
  @media (max-width: 425px){
    .restly-about-dec ul li {
      font-size: 15px;
    }
    .section-title h5 {
      font-size: 19px !important;
  }
     .single-solutions-box h3 a {
      font-size: 17px;
  }
   
    .differentiatorSec {
      background-position: inherit;
      background-color: #e9ecef85;
  }
   section.service-4 {
    padding: 27px 0px 0px !important;
  }
  .diffrent p {
    line-height: 27px;
    font-size: 14px !important;
  }
  h2.commonTitle__title.text-dark {
    font-size: 18px;
  }
  .section-title h2 {
    font-size: 19px !important;
    line-height: 26px !important;
  }
  section.service-4 .section-title {
    text-align: left !important;
  }
  .service-text-22 h1 {
    font-size: 19px !important;
  }
  
  section.service-4 .bar {
    position: relative;
    left: 0px;
    margin: 18px 0px !important;
  }
  .services-0 img {
    width: 70%;
    margin: 40px 40px 10px 60px;
}
  }
  
  @media(max-width:375px){
  .single-solutions-box {
      padding: 24px 35px 40px;
  }
  }
  
  @media (max-width: 320px){
  .single-solutions-box {
    padding: 24px 16px 40px;
  }
  }