.section-Tesimonials {
  background-color: #f8f9fa;
  padding: 40px 0px;
  text-align: center;
}
.tesimonials-30 .bar {
  margin: 10px auto !important;
}
.section-title h2 {
  color: rgb(19 47 112);
  margin-bottom: 0;

  text-transform: capitalize;
  font-size: 27px;
  font-weight: 500;
}
h4.client-say {
  color: rgb(19 47 112);
  font-weight: 400;
  color: #000;
  font-size: 19px;
  padding-top: 7px;
  margin-bottom: 0px;
}

.item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}
.client {
  margin: 60px 0px 0px 0px;
  padding: 85px 30px 27px;
  text-align: center;
  position: relative;
  background: #fff;
  -webkit-box-shadow: 0 0 20px #d0d7db;
  box-shadow: 0 0 20px #d0d7db;
  -webkit-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
  border-radius: 20px 0px 60px 10px;
  min-height: 349px;
}
/* .client .client-image {
    position: absolute;
    top: -60px;
    left: 100px;
    width: 120px;
    height: 120px;
    padding: 15px;
    margin-left: -60px;
    border-radius: 50%;
    overflow: hidden;
    display: inline-block;
    margin-bottom: 16px;
  } */

.client .client-image {
  position: absolute;
  top: -60px;
  left: 100px;
  width: 100px;
  height: 100px;
  padding: 3px;
  margin-left: -60px;
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;
  margin-bottom: 16px;
}
.client .client-image img {
  width: 100%;
  border-radius: 50%;
  box-shadow: 0 6px 10px 4px rgb(208 215 219 / 50%);
  padding: 14px;
}
@media (max-width: 425px) {
  .section-title h2 {
    font-size: 18px;
  }
  .client h4.client-say {
    font-size: 16px;
  }
}

@media (max-width: 991px) {
  .client {
    min-height: 417px;
  }
}
