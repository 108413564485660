/* .ml-banner-content h1 {
    margin-bottom: 15px;
    font-size: 45px;
    color: #fff;
    padding-top: 90px;
    font-weight: 700;
  }
  .ml-banner-content p {
    display: inline-block;
    width: 700px;
    text-align: center;
    font-size: 16px !important;
    font-weight: 400 !important;
    color: #fff !important;
    line-height: 26px;
    margin-bottom: 20px !important;
  } */
.start {
  margin: 0px 0px;
  width: 144px;
  height: 40px;
  border: none;
  border-radius: 4px;
  box-shadow: 0 10px 15px 0rgb (56 0 189 / 20%);
  background-color: #00adb8;
  padding: 7px 0px 0px 13px;
  color: #fff;
}
/* .carousel-caption {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    background: rgb(0 0 0 / 57%);
    top: 0;
  } */

/* .img-top-box {
    width: 100%;
    min-height: 550px;
    position: relative;
} */

.home-slider {
  position: relative;
  width: 100%;
  height: 550px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-image {
  width: 100%;
  height: 100%;
}

.home-slider:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.57);
}

.home-all-text {
  position: absolute;
  text-align: center;
}
.home-all-text h1 {
  font-weight: 700px;
  font-size: 45px;
  color: #fff;
  margin-bottom: 10px;
}
.home-paragraph {
  color: #fff !important;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 20px !important;

  width: 700px;
}
.home-btn {
  background-color: #ffa500 !important;
  border: none;
  border-radius: 20px;
  color: #000 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  height: 40px;
  width: 148px;
  display: block;
  margin: 0 auto;
}
.home-btn a {
  color: #000 !important;
}
.ml-banner-content.smtp {
  position: relative;
}
.maintbbt-tex.tt1 {
  position: relative;
}
.carousel-caption {
  position: relative;
  right: 0;
  bottom: 0;
  left: 0;
  color: #fff;
  /* background: rgb(0 0 0 / 57%); */
  top: 0;
  padding-top: 0px !important;
}
.start a {
  font-weight: 400;
  font-size: 14px !important;
  color: #fff !important;
}
.section-service {
  margin-top: -165px;
}
.single-solutions-box:hover {
  -webkit-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  -webkit-box-shadow: 0 2px 48px 0 rgb(0 0 0 / 8%);
}
.single-solutions-box p {
  font-size: 13px !important;
  color: #0000009e !important;
  margin-bottom: 0px;
}
.home-page-service-desktop .single-solutions-box {
  padding: 20px 15px;
  margin-bottom: 10px;
  text-align: center;
  transition: 0.5s;
  position: relative;
  z-index: 1;
  cursor: pointer;
  border: 1px solid #c5fffd;
  border-radius: 5px;
}
.single-solutions-box:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  border-radius: 5px;
  height: 100%;
  background: linear-gradient(
    to right top,
    #d5fefd,
    #e0fdff,
    #effbff,
    #fbfaff,
    #fffcff
  );
}
.single-solutions-box .icon {
  margin-bottom: 19px;
  position: relative;
  z-index: 1;
  display: inline-block;
}
.single-solutions-box .icon:before {
  content: "";
  position: absolute;
  left: 0px;
  top: 11%;
  z-index: -1;
  background: #f0fdfe;
  box-shadow: 0 2px 48px 0 rgb(0 0 0 / 9%);
  border-radius: 30% 70% 70% 30%/40% 60% 40% 60%;
  width: 60px;
  height: 60px;
}
.single-solutions-box h3 {
  font-size: 18px;
  margin-bottom: 5px;
}
.single-solutions-box h3 a {
  color: #000;
  display: inline-block;
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 0px;
}
.single-solutions-box:hover .icon img {
  transform: rotateY(-1turn);
}
.single-solutions-box .icon img {
  transition: 0.5s;
}
.home-page-service-desktop {
  display: block;
}
.home-page-service-mobile {
  display: none;
}
@media (max-width: 1200px) {
.home-page-service-desktop .single-solutions-box {
  padding: 20px 9px;
}
}

@media (max-width: 1024px) {
.single-solutions-box p {
  font-size: 16px !important;
}
.single-solutions-box {
  padding: 20px 6px;
}
.home-page-service-desktop .single-solutions-box {
  padding: 20px 35px;
}
}

@media (max-width: 991px) {
.single-solutions-box p {
  font-size: 14px !important;
}
}

@media (max-width: 767px) {
.home-page-service-desktop .single-solutions-box {
    padding: 20px 6px;
}
.home-page-service-desktop .single-solutions-box p {
  font-size: 13px !important;
}


  .home-paragraph {
    width: 100%;
  }
  .home-text {
    margin-bottom: 9px;
    font-size: 32px;
    padding-top: 59px;
  }
}
@media (max-width: 575px) {
  .home-slider {
    height: 450px;
}
  .home-text {
    max-width: 100%;
    font-size: 25px !important;
    padding: 10px !important;
    font-weight: 700;
}
  .home-page-service-desktop {
    display: none;
  }
  .home-page-service-mobile {
    display: block;
  }
}


@media (max-width: 430px){
  .section-service {
    margin-top: -84px;
}
.home-slider {
  height: 330px;
}
}



@media (max-width: 424px) {
  .home-text {
    padding-top: 0px;
  }

  .home-slider {
    height: 300px;
  }

  .home-page-service-mobile .single-solutions-box {
    margin-top: 115px !important;
}

.home-paragraph {
  font-size: 12px !important;
  padding: 0px 18px !important;
}

.home-btn {
  font-size: 12px !important;
  height: 35px;
  width: 125px;
}

}
@media (max-width: 375px) {
  .home-slider {
    height: 310px;
  }
  .home-text {
    padding-top: 0px;
  }
  .home-text {
    max-width: 100%;
    font-size: 23px !important;
  }
  .home-paragraph {
    font-size: 11px !important;
    line-height: 22px;
  }
  .home-page-service-mobile .single-solutions-box {
    margin-top: 94px !important;
}
}
@media (max-width: 425px) {
  .start {
    margin: 0px 18px;
  }

  button.home-btn.btn.btn-primary {
    margin: 0px;
  }
  .single-solutions-box h3 a {
    font-size: 17px !important;
  }
  .home-page-service-mobile .single-solutions-box {
    margin-bottom: 16px;
  }
}
@media (max-width: 320px) {
  .home-text {
    max-width: 100%;
    font-size: 20px;
  }
}
