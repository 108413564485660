.about-area.ptb-80{
    padding-bottom: 120px;
    padding-top: 40px !important;
    position: relative;
    background-image: url("../../assets/images/aboutarea11.png"); 
   width: 100%;
   background-repeat: no-repeat;
   background-size: cover;
   background-position: center; 
}
    
.app_item.item_one {
    background-image: linear-gradient(40deg, #e87e16 0%, #f0de14 100%);
    box-shadow: 0px 20px 40px 0px rgb(224 149 32 / 40%);
  }
  .app_item.item_two {
    background-image: linear-gradient(40deg, #5e2ced 0%, #a485fd 100%);
    box-shadow: 0px 20px 40px 0px rgb(94 44 237 / 40%);
  }

  .app_item.item_three {
    background-image: linear-gradient(40deg, #e03827 0%, #f9a47a 100%);
    box-shadow: 0px 20px 40px 0px rgb(224 56 39 / 40%);
  }

  .app_item.item_four {
    background-image: linear-gradient(40deg, #2171d4 0%, #2cc4f0 100%);
    box-shadow: 0px 20px 40px 0px rgb(44 130 237 / 40%);
  }
  .app_item {
    text-align: center;
    border-radius: 6px;
    padding: 15px 0px 25px;
    position: relative;
    margin-bottom: 10px;
}
  .w_color {
    color: #fff;
    font-size: 17px;
  }
  section.app_featured_area.about-usBg.ptb-50-100 {
    background: #e9ecef78;
    padding: 50px 0px;
}
  i.ti-headphone {
    font-size: 38px;
    color: #fff;
  }

  .swiper {
    width: 100%;
    height: 100%;
  }
  .ml-about-content.aos-init p {
    color: #fff !important;
    padding-top: 10px !important;
  }
  .ml-about-content h2 {
    font-size: 27px;
    font-weight: 500;
    color: #fff;
  }
  section.histry-text {
    position: relative;
    top: -42px;
  }
  .about-area {
    padding-top: 40px !important;
    padding-bottom: 120px;
  }
  .about-text1 i {
    font-size: 23px;
    color: #fff;
    width: 50px;
    height: 50px;
    box-shadow: 0 2px 48px 0 rgb(0 0 0 / 8%);
    border-radius: 50px;
    padding: 7px 12px;
    background: #00adb8;
  }
  .about-text1 h3 {
    font-size: 20px;
    margin-top: 10px;
    font-weight: 400;
    margin-bottom: 3px;
    color: #000;
  }
  section.updated.stack p {
    width: 743px;
    margin: auto;
  }
  .about-area.ptb-80 {
    margin-top: 0px !important;
  }
  .about-text1 {
    border-radius: 17px;
    padding: 20px 25px;
    background-color: #fff;
    border: 1px dashed #ccc;
    box-shadow: 0 2px 48px 0 rgb(0 0 0 / 8%);
  }
  
  .text-start {
    text-align: left !important;
  }

 
  .fw-bold-600 {
    font-weight: 600;
  }
  .person-info h3 {
    font-size: 17px;
    margin-bottom: 0px;
    padding: 10px 0px 0px 0px;
    color: rgb(19 47 112);
    font-weight: 600;
  }

  .section-team {
    text-align: center;
    padding: 18px 10px 0px 10px;
  }
  .section-team h2 {
    font-size: 26px !important;
    color: #000;
    font-weight: 400 !important;
    margin: 0px 0px 12px;
}
  .section-team .single-person .person-image img {
    border-style: dotted dashed solid double;
    padding: 0px 0px;
    border-radius: 50%;
    width: 150px;
    height: 150px;
  }
  section.updated {
    padding: 50px 0px 50px 0px;
    background-color: #e9ecefa1;
  }
  .about-text1:hover {
    cursor: pointer;
    box-shadow: 0 5px 15px 0 rgb(63 81 181 / 38%);
  }
  .section-team .single-person {
    background-color: #ced4da3b;
    border-radius: 5px;
    margin-bottom: 0px;
    cursor: pointer;
    margin-top: 10px;
    padding: 40px 0px;
  }
  .ml-about-content {
    text-align: center;
    position: relative;
  }
  .about-area.ptb-80:before {
    content: "";
    background-color: rgb(0 0 0 / 18%);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  .person-info {
    padding-top: 20px;
  }
  .section-team .bar {
    margin: 8px auto !important;
}
.ml-about-content .sub-title {
  display: inline-block;
  color: #000;
  background-color: #ffa500;
  margin-bottom: 7px;
  border-radius: 30px;
  padding: 5px 20px 4px;
  font-size: 14px;
  font-weight: 500;
}
  .about-area.ptb-80 {
    margin-top: 50px;
  }
  .ml-about-content .bar {
    height: 5px;
    width: 90px;
    background: #93cbcf7a;
    position: relative;
    border-radius: 30px;
  }
  .ml-about-content .bar:before {
    content: "";
    position: absolute;
    left: 0;
    top: -2.7px;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: #30cfcb;
  }

  @media (max-width: 767px) {
    .counte-img p {
      text-align: center;
    }
    .counte-img h2 {
      text-align: center;
    }
   
  }
  @media (max-width: 425px) {
    .ml-about-content h2 {
      font-size: 18px;
      line-height: 26px;
    }
  }
  @media (max-width: 1060px) {
    .about-text1 {
      margin-top: 29px;
    }
  }
  @media (max-width: 991px) {
    .about-text1 {
      margin-top: 16px;
    }
  }

  @media (max-width: 767px) {
    .about-text1.our-histtory {
      margin-bottom: -85px;
    }
    section.histry-text {
      position: relative;
      top: -117px;
    }
    .app_fetured_item {
      margin-top: 20px;
  }
  }
  @media (max-width: 575px) {
 
section.app_featured_area.about-usBg.ptb-50-100 {
  background: #e9ecef78;
  padding: 30px 0px;
}
.app_item {
  margin-top: 13px;
  padding: 0px 0px 6px 0px;
  margin-bottom: 8px;
}
.w_color {
  font-size: 14px;
}
  }
  @media (max-width: 425px) {
    .about-text1 h3 {
      font-size: 18px;
    }
    .about-area.ptb-80 {
      padding-bottom: 71px;
      padding-top: 0px !important;
    
  }
    /* .section-team h2 {
      font-size: 18px;
    } */
 
    .about-text1 {
      margin-top: 18px;
    }
    .ml-about-content .sub-title {
      margin-bottom: 3px;
    }
  }