.navbar {
	height: 80px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px !important;
	font-size: 1.2rem;
}
nav.navbar.navbar-expand.navbar-light.bg-light {
  background-color: #fff !important;
}
.nav-logo {
	text-decoration: none;
  position: relative;
  top: -11px;
}
.nav-links {
	display: flex;
	grid-template-columns: repeat(4, auto);
	grid-gap: 32px;
	list-style: none;
}
.nav-link  {
  color: #000 !important;
  font-size: 15px !important;
  font-weight: 300 !important;
  padding-top: 10px !important;
  clear: both;
  position: relative;
  align-items: center;
}


/* .nav-item-1 .nav-link {
  width: 164px;
  justify-content: center;
  color: #b56969 !important;
  text-align: center;
  height: 40px;
  font-weight: 500 !important;
  font-size: 13px !important;
  border-radius: 20px;
  background-color: #f8edfd;
} */

.nav-item-1 .nav-link {
  width: 164px;
  justify-content: center;
  color: #000 !important;
  text-align: center;
  height: 40px;
  font-weight: 500 !important;
  font-size: 14px !important;
  border-radius: 20px;
  background-color: #ffa500;
  /* padding: 0px 0px !important; */
}


/* .nav-item-1 {
  border-radius: 0px;
  background-color: #00adb8;
} */
.nav-link.activeheader {
  color: #000 !important;
  border-bottom: 2px solid;
}
.nav-link:hover {
  color: #00adb8 !important;
  
}
.nav-item-1 .nav-link:hover{
  color: #b56969 !important;
}
.nav-icon {
  display: none;
  font-size: 23px;
  cursor: pointer;
  position: relative;
  top: -11px;
}
@media(max-width:1082px){
  .nav-links {
    grid-gap: 19px;
  }
}
@media(max-width:998px){
  .navbar {
    padding: 6px !important;
  }
}
@media only screen and (max-width: 991px) {
  .nav-link.activeheader {
    color: rgb(8 44 124) !important;
    border-bottom: none;
}
	.navbar {
		position: relative;
	}
  .nav-item-1 a.nav-link {
  
    padding-top: 9px !important;
    border: 1px solid #ffffff70;  
}
  .nav-link {
    padding-top: 0px !important;
  
}
.nav-links {
  grid-gap: 12px;
  
}
	.nav-links {
		display: flex;
		flex-direction: column;
		position: absolute;
		text-align: center;
		width: 100%;
		top: 74px;
		left: -1001%;
	
	}
  .nav-link {
    text-align: left;
    color: #fff !important;
   
}
	.nav-links.active {
    padding: 10px;
		background: #00adb8;
		left: 0;
	}
	.nav-item {
    padding-bottom: 15px;
    border-bottom: 1px solid #ffffff78;
}
	.nav-icon {
		display: flex;
	}
}
