section.Counter {
    padding: 50px 0px 0px 0px;
  }
  .ct-conu p {
    margin-bottom: 0px;
  }
  .counte-img h2 {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    color: rgb(19 47 112);
    margin-bottom: 16px !important;
}
  .counter h3 {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 0px;
    color: #00adb8;
  }
  .counte-img p {
    padding: 20px 0px !important;
    text-align: center;
  }
  .mat-text {
    position: absolute;
    top: 20px;
    right: 15px;
  }
  .contact-cta-box {
    border: dashed 1px #d9cfcf;
    position: relative;
    padding: 20px;
  }
  .ct-conu h3 {
    font-size: 19px;
    color: #000 !important;
    margin-bottom: 5px;
    font-weight: 700;
  }
  .odometer {
    font-size: 30px;
  }
  h2.counter-set {
    text-align: center;
  }
  .counter {
    background-color: #f8f9fad1;
    padding: 20px 0;
    border-radius: 5px;
    margin-bottom: 5px;
  }
  .counter p {
    margin-bottom: 0px;
    padding-top: 3px !important;
  }
  .fa-2x {
    margin: 0 auto;
    float: none;
    display: table;
    color: #4ad1e5;
  }

  @media (max-width: 1199px) {
    .offset-2 {
      margin-left: 0px;
    }
    .counte-img h2 {
      font-size: 20px;
    }
  }

  @media (max-width: 991px) {
    .counte-img h2 {
      line-height: 32px !important;
    }
  }
  @media (max-width: 767px) {
    .mat-text {
      position: relative;
      top: 8px;
      right: 0px;
    }
    .contact-cta-box {
      padding: 16px 26px 21px 20px;
    }
  }

  @media (max-width: 575px) {
    .counte-img h2 {
      text-align: center;
    }
    .counte-img p {
      text-align: center;
    }
  }

  @media (max-width: 425px) {
    .counter h3 {
      font-size: 19px !important;
    }

    .counte-img h2 {
      font-size: 18px;
      line-height: 26px !important;
    }
    .ct-conu h3 {
      font-size: 16px;
    }
    counte-img p {
      padding: 15px 20px 0px !important;
    }
  }
  @media (max-width: 375px) {
    .count-1 h3 {
      margin-top: 36px !important;
      font-size: 25px;
    }
  }

  @media (max-width: 336px) {
    .counter p {
      font-size: 12px !important;
      margin-bottom: 0px;
      padding-top: 3px !important;
    }
    .counter h3 {
      font-size: 17px !important;
    }
  }