.request p {
    color: #fff !important;
    margin-bottom: 0px;
  }
  .banner-slide:before {
    content: "";
    background: #5454542e;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }
  .react-tel-input .invalid-number-message {
    background: transparent;
  }
  .react-tel-input .form-control::placeholder{
    color: #6c757d !important;
  }
  .request-form-1 {
    background: #f4f6f8;
    padding: 50px 0px 0px;
    margin-bottom: -8%;
  }
  .banner-slide-1 p {
    text-align: center;
    color: #ffffffd6 !important;
}
  p.help.is-danger.new_file {
    float: left;
    position: relative;
    top: 9px;
    margin-left: -76px;
  }
  .start_date label.Request_field{
    font-size: 13px ;
    color: #5b5c60;
    font-weight: 500;

  }
  .end_date label.Request_field{
    font-size: 13px ;
    color: #5b5c60;
    font-weight: 500;
  }
  .form-t12 {
    background: #203391;
    width: 70px;
    height: 50px;
    margin-right: 10px;
    font-size: 30px;
    padding: 0px 12px;
    border-radius: 5px;
    color: #fff;
  }
  .request-form-2 h3 {
    font-size: 25px;
    color: rgb(19 47 112) !important;
    font-weight: 600;
  }
  .slider-parent input[type="range"] {
    width: 100%;
  }
  .contact-8 {
    padding: 22px 0px 0px;
  }
  .contactrequest-12 {
    position: relative;
    top: -139px;
  }
  label.Request_field {
    font-size: 13px;
    color: #414d58;
    font-weight: 500;
}
input.range {
  width: 100%;
}
  input.date-picker {
    width: 100%;
    border-radius: 3px;
    padding: 12px;
    margin-top: 8px;
    border: 1px solid #f0f1f3;
    font-size: 14px;
    color: #6c757d;
    background-color: #f0f1f3;
  }
  .react-tel-input .flag-dropdown {
    background-color: #f0f1f3;
    border: 1px solid #ededed;
  }
  .react-tel-input .form-control {
    height: 41px;
    color: #6c757d;
    width: 100%;
    border-radius: 3px;
    line-height: 21px;
    margin-top: 6px !important;
    padding-left: 46px;
    margin-bottom: 10px !important;
  }
  .form-control {
    border-radius: 3px;
    padding: 10px;
    color: #6c757d;
    border: 1px solid #f0f1f3;
    font-size: 14px;
    margin-bottom: 10px;
    background-color: #f0f1f3 !important;
  }
  .form-control:focus {
    border: 1px solid #f0f1f3 !important;
    box-shadow: none !important;
    background-color: #f0f1f3 !important;
  }

  .form-select {
    border-radius: 3px;
    padding: 10px;
    margin-top: 8px;
    border: 1px solid #f0f1f3;
    font-size: 14px;
    color: #6c757d;
    background-color: #f0f1f3;
  }
  .form-select:focus {
    color: #000 !important;
    border: 1px solid #f0f1f3 !important;
    box-shadow: none !important;
    background-color: #f0f1f3 !important;
  }
  .input.date-picker:focus {
    color: #000 !important;
  }
  .react-tel-input .flag-dropdown {
    background-color: #f0f1f3;
    border: 1px solid #adb5bd2b !important;
  }
  .react-tel-input .form-control.invalid-number {
    border: none !important;
    background-color: #f0f1f3;
    /* background-color: transparent; */
    /* border-left-color: #cacaca; */
  }
  .form-contact {
    background: #ffffff none repeat scroll 0 0;
    box-shadow: 0px 0px 8px 0px rgb(8 18 / 10%);
    overflow: hidden;
    padding: 15px 25px;
    border-radius: 0px 0px 10px 10px;
    position: relative;
  }
  .form-t11 {
    background: #f5f4f7;
    padding: 29px 25px 22px;
    position: relative;
    border-radius: 10px 10px 0px 0px;
  }
  label.budset-rang-text.form-label {
    font-size: 14px;
    font-weight: 500;
    color: rgb(19 47 112) !important;
  }

  .request {
    top: 23px;
    position: relative;
  }

  a.contact-9 {
    font-weight: 600;
    color: rgb(19 47 112) !important;
  }

  .field.file-upload {
    position: relative;
    text-align: left;
    width: 100%;
    margin-bottom: 10px;
  }

  p.errormessage.new {
    padding-top: 11px !important;
    margin-left: -72px;
    /* padding-left: 0px !important; */
  }

  .banner-slide-1 h5 {
    font-size: 34px;
    text-align: center;
    font-weight: 600;
    color:#fff;
  }
  button.banner-slide-btn.btn.btn-primary {
    background-color: transparent;
    border-radius: 5px;
    border: 1px solid #fff;
    padding: 10px 46px;
  }
  button.banner-slide-btn.btn.btn-primary a {
    color: #fff;
  }
  .step {
    top: 35px;
    padding: 0 20px 63px 50px;
    position: relative;
  }

  .step::before {
    content: "";
    position: absolute;
    height: 25px;
    width: 25px;
    background-color: #f0547f;
    border-radius: 15px;
    left: calc(50px / 2);
    transform: translateX(-45%);
    z-index: 2;
  }

  .step::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 3px;
    background-color: #bcd2ea;
    left: calc(50px / 2);
    top: 0;
    z-index: 1;
  }
  .step.step.minimized-1 {
    padding: 0px 20px 0px 50px;
  }
  .step.minimized-1::after {
    height: auto;
  }
  .form-t11 {
    display: flex;
  }
  .step-header h4 {
    font-size: 18px;
    color: rgb(19 47 112) !important;
    margin-bottom: 0px;
    font-weight: 500;
    padding-bottom: 10px;
  }

  button.main-btn-resuest {
    background-color: #ffa500;
    border: none;
    border-radius: 5px;
    padding: 11px 25px;
    width: 100%;
    font-weight: 500;
    color: #000;
    font-size: 18px;
    border: 1px solid #dee2e6;
    margin-top: 7px;
}
  .step-1 {
    position: absolute;
    left: 21px;
    z-index: 9;
    color: #fff;
    padding: 2px;
    font-weight: 600;
    font-size: 13px;
  }

  @media (max-width: 1199px) {
    .contactrequest-12 {
      position: relative;
      top: -162px;
    }
    .request {
      top: 0px;
    }
  }

  @media (max-width: 991px) {
    .step {
      top: 17px;
      padding: 0 20px 45px 50px;
    }
    .contactrequest-12 {
      top: 33px;
      margin-bottom: 66px;
    }
    .form-t11 {
      background: #ecebef;
    }
    .request-form-1 {
      margin-bottom: 0px;
    }
    .banner-slide {
      text-align: left;
    }
  
  }
  @media (max-width: 767px) {
    button.main-btn-resuest {
      font-size: 16px;
      font-weight: 600;
  }
    .request-form {
      padding: 40px 0px 40px 0px;
    }

    .slider {
      width: 100%;
    }
    .thumb {
      width: 368px;
    }

    .mb-3.new-comapny {
      margin-bottom: 0px !important;
    }
  }

  @media (max-width: 575px) {
    .banner-slide-1 h5 {
      font-size: 22px;
    }
    .banner-slide {
      padding: 20px 0px;
    }

    .request-form-2 h3 {
      font-size: 20px;
    }
    .request-form-1 {
      padding: 31px 0px 0px;
    }
    .step {
      top: 0px;
      padding: 0 20px 25px 50px;
    }
    .contactrequest-12 {
      top: 0px;
      margin-bottom: 35px;
    }
    .thumb {
      width: 403px;
    }
  }
  @media (max-width: 480px) {
    .banner-slide-1 h5 {
      font-size: 21px;
    }

    img.banner-slide-2 {
      width: 84px;
    }
  }
  @media (max-width: 546px) {
    .thumb {
      width: 374px;
    }
  }
  @media (max-width: 522px) {
    .thumb {
      width: 349px;
    }
  }

  @media (max-width: 493px) {
    .thumb {
      width: 320px;
    }
  }

  @media (max-width: 458px) {
    .thumb {
      width: 286px;
    }
  }
  @media (max-width: 425px) {
    .form-contact {
      padding: 25px 20px;
    }
    .form-t11 {
      padding: 23px 20px 6px;
    }
    .request-form-2 h3 {
      font-size: 18px;
    }
    .step-header h4 {
      font-size: 16px;
      padding-bottom: 5px;
    }
    button.main-btn-resuest {
      font-size: 13px;
    }
    .request h3 {
      font-size: 19px;
    }
  }
  @media (max-width: 320px) {
    .step {
      top: 0px;
      padding: 0 20px 4px 50px;
    }
  }