  .new-prject {
    float: right;
  }
* .ml-projects-area .section-title {
    text-align: left;
} 


  section.project-slider.ml-projects-area h2 {
    font-size: 27px !important;
    font-weight: 500;
    color: rgb(19 47 112);
    text-transform: capitalize;
  }


  .single-ml-projects-box {
    margin-left: 15px;
    margin-right: 15px;
  }
  .single-ml-projects-box img.port-img {
    width: 100%;
    padding: 0px;
  }