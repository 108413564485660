section.contact-4:before {
  content: "";
  background-image: url("../../assets//images/dots.png");
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: -2;
}
label.Contactform-lb {
  font-size: 14px;
  color: #6c757d;
}
.contact-7 {
  text-align: center;
  margin-top: 24px;
}
.heading {
  text-align: center;
  max-width: 555px;
  margin: 0 auto 40px;
  position: relative;
}
/* .heading h3 {
  font-size: 28px;
  margin-bottom: 10px;
  position: relative;
  font-weight: 700;
  line-height: 35px;
} */

.Chooseus-cardbox .cardbox-main a {
  color: #fff;
}

.Chooseus-cardbox .cardbox-main {
  background: #40404063;
  padding: 30px;
  border-radius: 10px;
  text-align: center;
}

.chosseus-img {
  position: relative;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  font-size: 26px;
  color: #f16622;
  background: #484848;
  text-align: center;
  margin-bottom: 15px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Chooseus-cardbox .cardbox-main h3 {
  margin-bottom: 0px;
  font-size: 17px;
  font-weight: 500;
  line-height: 40px;
}
.section-title p.mt-3 {
  text-align: center;
  width: 639px;
  margin: auto;
  margin-bottom: 20px;
}
.section-title h3 {
  color: rgb(19 47 112);
  text-align: center;
}
.contact-suports h2 {
  font-size: 25px;
  position: relative;
  color: rgb(19 47 112);
  padding-bottom: 8px;
}
.contact-suports h2:after {
  content: "";
  border-top: 3px solid #3bb3c2;
  position: absolute;
  left: 0;
  transition: all 0.5s ease-in-out 0.5s;
  display: inline-block;
  bottom: 0;
  width: 150px;
}
.contact-suports img {
  margin: auto;
  width: 400px !important;
  margin-top: 0px !important;
  display: block;
}
.common-heading-text-11 h2 {
  margin-bottom: 0;
  text-transform: capitalize;
  font-size: 27px;
  font-weight: 500;
  color: rgb(19 47 112);
}

.skyline-img {
  background: #f7f6f6;
  border-radius: 8px 40px 8px 0;
  box-shadow: 0 3.4px 2.7px -30px rgb(0 0 0 / 6%),
    0 8.2px 8.9px -30px rgb(0 0 0 / 7%), 0 25px 40px -30px rgb(0 0 0 / 20%);
  padding-bottom: 15px;
  padding-top: 0px;
}
img.inodre {
  width: 100%;
}
.office-text {
  padding: 20px 51px 25px 0px;
}

.office-text p {
  font-size: 15px !important;
}

.office-text h4 {
  font-size: 20px;
  font-weight: 400;
  margin: 0 0 10px;
  color: #000;
}

.map {
  margin-top: 7%;
}

section.contact-4 {
  padding-top: 30px;
  padding-bottom: 30px;
  position: relative;
}
.common-heading-text-11 {
  text-align: center;
  padding: 20px;
}
@media (max-width: 1024px) {
.Chooseus-cardbox .cardbox-main {
  padding: 10px;
}

.Chooseus-cardbox .cardbox-main h3 {
  font-size: 15px;
}
p {
  font-size: 13px !important;
}
}


@media (max-width: 991px) {
  .Chooseus-cardbox .cardbox-main {
    background: #40404063;
    padding: 15px 18px;
    border-radius: 10px;
  }
  .Chooseus-cardbox .cardbox-main h3 {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .Chooseus-cardbox .cardbox-main {
    padding: 15px 29px;
    margin-bottom: 10px;
  }
  .section-title p.mt-3 {
    width: 100%;
    margin-bottom: 14px !important;
  }
}
@media (max-width: 575px) {
  .contact-suports img {
    width: 70% !important;
  }
  .bringing-head {
    font-size: 16px !important;
  }
  section.contact-4 {
    padding-top: 24px;
    padding-bottom: 17px;
  }
  .common-heading-text-11 h2 {
    font-size: 20px;
  }
  .common-heading-text-11 span {
    font-size: 13px;
  }
  .contact-suports h2 {
    font-size: 19px;
  }
  .office-text h4 {
    font-size: 17px;
    margin: 0 0 5px;
  }
}
@media (max-width: 425px) {
  .contact-suports h2 {
    font-size: 18px;
  }
  .contact-suports img {
    width: 80% !important;
}
}

p.mobile-error {
  font-size: 12px !important;
  padding-top: 5px !important;
  color: #d51616 !important;
}

ul.icon-ul-box {
  display: flex;
  align-items: center;
  padding: 0px;
}

/* ul.icon-ul-box li a img {
  width: 100%;
  height: 70px;
  padding: 14px;
} */

ul.icon-ul-box li a img {
  width: 100%;
  height: 45px;
  /* padding: 14px; */
  padding-right: 25px;
  margin-top: 10px;
}

.contact-socal-icon h5 {
  font-size: 22px;
  margin-top: 30px;
  color: rgb(19 47 112);
  text-transform: capitalize;
}

@media (max-width: 575px) {
  .contact-socal-icon h5 {
    font-size: 18px;
  }

  ul.icon-ul-box li a img {
    height: 35px;
  }
}
